import { useStore } from "@nanostores/preact";
import { isCartModified, isCartOpen, showCartItemQuantity } from "./cartStore";

export default function CartFlyoutToggle() {
    const $isCartOpen = useStore(isCartOpen);
    const $isCartModified = useStore(isCartModified);
    
    function showToCartQuantity(){
        // console.log($isCartModified);
        return showCartItemQuantity();
    }

    return $isCartModified ? (
        <button class="text-white text-sm flex items-center w-full h-full" onClick={() => isCartOpen.set(!$isCartOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"/></svg>
            Carrito {showToCartQuantity()}
        </button>
    ) : 
    (
        <button class="text-white text-sm flex items-center w-full h-full" onClick={() => isCartOpen.set(!$isCartOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"/></svg>
            Carrito {showToCartQuantity()}
        </button>
    )
}